import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../DangKyHoSo/utils';
import { CRUD_MODELS, EDUCATE_DEGREE_MODEL, WORKING_PROCESS_MODEL } from '../../../../constants';

function CongTacTable({ rows, setDialog, ...props }) {
  const columns = [
    {
      ...WORKING_PROCESS_MODEL.endTime,
      width: '30%',
      label: 'Thời gian công tác',
      render: (_, row) => `${row.startTime} - ${row.endTime}`,
    },
    { ...WORKING_PROCESS_MODEL.position },
    { ...WORKING_PROCESS_MODEL.unit },
    {
      key: 'link',
      label: 'Tác vụ',
      width: '20%',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.WORKING_PROCESS}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default CongTacTable;
