import React, { useState, Fragment } from "react";
import {
  Box,
  Card,
  Select,
  Divider,
  MenuItem,
  CardHeader,
  IconButton,
  Typography,
  CardContent,
  FormControl,
  InputLabel,
  TableHead,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CrudTable from "../../../components/Crud/CrudTable";
import KPTextform from "../../../components/KPTComponents/KPTextform";
import DefaultLabel from "../../../components/CommonComponents/DefaultLabel";

import { textForm } from "../utils";
import { CRUD_MODELS, CRUD_LABELS, SCIENTIFIC_RESEARCH_CONSTANTS, USER_CONSTANTS } from "../../../constants";
import { toPlural } from "../../../utils";


const LIST_CTTK = [
  { id: '4', key: CRUD_MODELS.TRAINEE_GUIDANCE },
  { id: '5', key: CRUD_MODELS.MONOGRAPH },
  { id: '6', key: CRUD_MODELS.SCIENTIFIC_TASK },
  { id: '7.1', key: CRUD_MODELS.SCIENTIFIC_RESEARCH },
  { id: '7.2', key: CRUD_MODELS.SOLUTION },
  { id: '7.3', key: CRUD_MODELS.AWARD },
  { id: '8', key: CRUD_MODELS.RESEARCH_PROJECT },
];

function MucB({ error, values, setValues, dialog, setDialog, setPreview, ...props }) {
  const [srFilterType, setSrFilterType] = useState(SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE[0].key);

  const { id } = useParams();
  const { formReducer } = useSelector((state) => state);

  const profile = useSelector(state => state.profileReducer.profile);

  const disabled = !!id && profile?.userRole !== USER_CONSTANTS.ROLE.super_admin;

  return (
    <Fragment>
      <Card id='mucB_muc1_2' style={{ width: '100%', marginTop: 16 }}>
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={values}
            setValues={setValues}
            textForm={textForm.mucB.muc1}
          />
        </CardContent>
      </Card>

      <Card style={{ width: '100%', marginTop: 16 }}>
        <CardHeader title={
          <Typography className='kpt-textfield-label'>
            <b>2. Thời gian, kết quả tham gia đào tạo, bồi dưỡng từ trình độ đại học trở lên</b>
          </Typography>
        } />
        <Divider />
        <CardContent>
          <DefaultLabel label='Tổng số năm thực hiện nhiệm vụ đào tạo' required={true} />
          <KPTextform
            component={Box}
            error={error}
            values={values}
            setValues={setValues}
            textForm={textForm.mucB.muc2}
          />
        </CardContent>
        <Divider />
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Typography>
            <b>
              Khai cụ thể ít nhất 6 năm học, trong đó có 03 năm học liên tục tính đến ngày hết hạn nộp hồ sơ 
              (ứng viên GS chỉ khai 3 năm cuối liên tục sau khi được công nhận PGS)
            </b>
          </Typography>
        } />
        <Divider />
        <TableContainer component={Box}>
          <Table>
            <TableHead style={{ backgroundColor: '#f2f2f2' }}>
              <TableRow>
                <TableCell width='20%' rowSpan={2}><b>Năm học</b></TableCell>
                <TableCell width='20%' colSpan={2} align="center">
                  <b>Số lượng NCS đã hướng dẫn</b>
                </TableCell>
                <TableCell width='20%' colSpan={2} align="center">
                  <b>Số lượng ThS/CK2/BSNT đã hướng dẫn</b>
                </TableCell>
                <TableCell width='20%'  rowSpan={2} align="center">
                  <b>Tổng số giờ chuẩn gd trực tiếp trên lớp/số giờ chuẩn gd quy đổi/số giờ chuẩn định mức</b>
                </TableCell>
                <TableCell width='20%' rowSpan={2} align="center">
                  <b>Tác vụ</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><b>Chính</b></TableCell>
                <TableCell align="center"><b>Phụ</b></TableCell>
                <TableCell align="center"><b>ĐH</b></TableCell>
                <TableCell align="center"><b>SĐH</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[0,1,2,3,4,5].map((id) => {
                const row = values.educateProcess[id];
                return (
                  <Fragment key={`row_${id}`}>
                    {id == 3 && (
                      <TableRow>
                        <TableCell colSpan={7} align="center"><b>3 năm học cuối</b></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>{row?.schoolYear || 'N/A'}</TableCell>
                      <TableCell align="center">{row?.main || 0}</TableCell>
                      <TableCell align="center">{row?.other || 0}</TableCell>
                      <TableCell align="center">{row?.dh || 0}</TableCell>
                      <TableCell align="center">{row?.sdh || 0}</TableCell>
                      <TableCell align="center">{row?.dm || 0}</TableCell>
                      <TableCell>
                        <Typography className='action-btn' color='primary' variant='body2' onClick={() => {
                          setPreview(row?.proof);
                        }}>
                          Minh chứng
                        </Typography>
                        <Typography disabled={disabled} className='action-btn' color='primary' variant='body2' onClick={() => setDialog({
                          values,
                          setValues,
                          saveForm: props.saveForm,
                          type: CRUD_MODELS.EDUCATE_PROCESS,
                          defaultValues: { ...row, id },
                        })}>
                          Chi tiết
                        </Typography>
                        {/* <Typography className='action-btn' variant='body2' color='error' onClick={() => {
                          const educateProcess = formReducer.form.educateProcess.filter(
                            (process) => process.schoolYear !== row.schoolYear
                          );
                          handleSubmit({ educateProcess });
                        }}>
                          Xóa
                        </Typography> */}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Card id='mucB_muc3' style={{ width: '100%', marginTop: 16 }}>
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={values}
            setValues={setValues}
            textForm={textForm.mucB.muc3}
          />
        </CardContent>
      </Card>

      {LIST_CTTK.map(({ id, key }) => (
        <Card
          key={`mucB_muc${id}`}
          id={`mucB_muc${id.replace('.', '_')}`}
          style={{ width: '100%', marginTop: 16 }}
        >
          {(() => {
            switch (key) {
              case CRUD_MODELS.SCIENTIFIC_RESEARCH:
                return (
                  <Fragment>
                    <CardHeader sx={{ py: 2, pr: 1 }} title={
                      <Typography className='kpt-textfield-label'>
                        <b>{id}. {CRUD_LABELS[key]}</b>
                      </Typography>
                    } />
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                      <FormControl sx={{ m: 1, width: 310 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Chọn phân loại</InputLabel>
                        <Select
                          id="demo-simple-select-autowidth-label"
                          size='small'
                          label='Chọn phân loại'
                          value={srFilterType}
                          onChange={(e) => setSrFilterType(e.target.value)}
                        >
                          {SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE.map((type) => (
                            <MenuItem key={type.key} value={type.key}>{type.title}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box>
                        <IconButton disabled={disabled} color='primary' onClick={() => setDialog({
                          filterType: srFilterType,
                          type: CRUD_MODELS.SCIENTIFIC_RESEARCH
                        })}>
                          <Add />
                        </IconButton>
                      </Box>
                    </Box>
                  </Fragment>
                )
              default:
                return (
                  <CardHeader sx={{ py: 1, pr: 1 }} title={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography className='kpt-textfield-label'><b>{id}. {CRUD_LABELS[key]}</b></Typography>
                      <IconButton disabled={disabled} color='primary' onClick={() => setDialog({ defaultValues: {}, type: key })}><Add /></IconButton>
                    </Box>
                  } />
                )
            }
          })()}
          <CrudTable
            type={key}
            setDialog={setDialog}
            setPreview={setPreview}
            filterType={srFilterType}
            setDeleteCrud={props.setDeleteCrud}
            rows={formReducer[toPlural(key)]}
          />
        </Card>
      ))}

      <Card id='mucB_muc9' style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Typography className='kpt-textfield-label'>
            <b>9. Các tiêu chuẩn không đủ so với quy định, đề xuất công trình khoa học (CTKH) thay thế</b>
          </Typography>
        } />
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={values}
            setValues={setValues}
            textForm={textForm.mucB.muc9}
          />
        </CardContent>
      </Card>
    </Fragment>
  )
}

export default MucB;
