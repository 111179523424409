import React, { useState, Fragment, useEffect } from "react";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import MucA from "../Muc/MucA";
import MucA0 from "../Muc/MucA0";
import MucB from "../Muc/MucB";
import MucC from "../Muc/MucC";
import MucD from "../Muc/MucD";
import MucE from "../Muc/MucE";

import ThanhTichInsert from "../ThanhTichInsert";
import PreviewModal from "../../../components/CommonComponents/PreviewModal";

import { getError } from "../../../components/KPTComponents/KPTextform/utils";
import { textForm } from "../utils";
import { DEFAULT_ERROR } from "../../../components/KPTComponents/KPTextform/KPTextform";
import { apiCall, convertBeToFe, convertFeToBe, jumpToReleventDiv, toPlural } from "../../../utils";
import { formActions, profileActions, userActions } from "../../../actions";
import { CRUD_LABELS, CRUD_MODELS, COLOR_PALETTE, FORM_MODEL, PROFILE_MODEL, FORM_CONSTANTS, HEADERS } from "../../../constants";
import PopupMenu from "../../../components/CommonComponents/PopupMenu";
import { useParams } from "react-router-dom";

const TitleHeader = ({ id, title, marginTop }) => {
  return (
    <Card id={id} style={{ width: '100%', marginTop }}>
      <Box height={6} bgcolor={COLOR_PALETTE.PRIMARY} />
      <Box p={2}>
        <Typography variant='h5' style={{
          fontFamily: "SFProDisplay-bold", textAlign: 'center', textTransform: 'uppercase'
        }}>
          <b>{title}</b>
        </Typography>
      </Box>
    </Card>
  );
}

const DEFAULT_DELETE_CRUD_STATE = {
  type: '',
  anchorEl: null,
  handleDelete: null,
}

function DangKyHoSoUpdate() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { form, profile } = useSelector((state) => ({
    form: state.formReducer.form,
    profile: state.profileReducer.profile,
  }));

  const [values, setValues] = useState(null);
  const [preview, setPreview] = useState(null);
  const [profileValues, setProfileValues] = useState(null);
  
  const [error, setError] = useState({ ...DEFAULT_ERROR });
  const [dialog, setDialog] = useState(null);
  const [deleteCrud, setDeleteCrud] = useState({ ...DEFAULT_DELETE_CRUD_STATE });

  const handleReload = () => {
    if (id) {
      dispatch(formActions.getApplicationFormById(id));
    } else {
      dispatch(formActions.filterApplicationForm({ profileId: profile.id })).then(async (res) => {
        if (res) {
          dispatch(formActions.getApplicationFormById(res.id));
        } else {
          await saveForm({ status: FORM_CONSTANTS.STATUS_MAP.draft.value });
        }
      });
    }
  }

  const closeDeleteMenu = () => {
    setDeleteCrud({ ...DEFAULT_DELETE_CRUD_STATE });
  }

  const saveForm = async (additionalValues={}) => {
    const _payload = {
      ...values,
      ...additionalValues,
      profileId: values?.profileId || profile.id
    }
    const converted = await convertFeToBe(_payload, FORM_MODEL, '/form/' + _payload.id);

    if (converted.error) {
      dispatch(userActions.notification({ message: converted.error, type: 'error' }));
      return;
    }

    const payload = { ...converted.values };
    Object.keys(CRUD_MODELS).forEach((_key) => {
      const key = toPlural(CRUD_MODELS[_key]);
      if (payload.hasOwnProperty(key)) payload[key] = undefined; 
    });

    if (payload.id) {
      dispatch(formActions.updateApplicationForm(payload));
    } else {
      dispatch(formActions.insertApplicationForm(payload)).then((success) => {
        if (success) window.location.reload();
      });
    }
  }

  const saveProfile = async () => {
    const converted = await convertFeToBe({ ...profileValues }, PROFILE_MODEL, '/profile/' + profile.id);
    dispatch(profileActions.updateProfile(converted.values));
  }

  const validate = (values, textform) => {
    for (const elm of textform) {
      const err = getError(values, elm);
      if (!!err) {
        setError({ key: elm.key, value: err });
        jumpToReleventDiv(elm.key);
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm.label}`,
          type: 'error'
        }));
        // window.history.pushState(null, null, `#${elm.key}`);
        return false;
      }
    }

    return true;
  }

  const handleSubmit = async (status) => {
    if (status === FORM_CONSTANTS.STATUS_MAP.submitted.value) {
      const validateTextForm = [
        ...textForm.mucA0,
        ...textForm.mucA.muc7,
        ...textForm.mucA.muc8,
        ...textForm.mucB.muc1,
        ...textForm.mucB.muc2,
        ...textForm.mucB.muc9,
        ...textForm.mucC,
        ...textForm.mucE,
      ];

      const validatedForm = validate(values, validateTextForm);
      const validatedProfile = validate(profileValues, textForm.mucA.muc1_6);
      if (!validatedForm || !validatedProfile) return;
    }
    saveForm({ status });
    saveProfile();
  }

  // Vừa vào trang thì lấy thông tin hồ sơ luôn
  useEffect(() => {
    if (!isNaN(profile?.id)) {
      if (!form) handleReload();
      // if (!profileValues) setProfileValues({ ...profile });
    }
  }, [profile]);

  useEffect(() => {
    if (form) {
      setValues(form);
      if (!profileValues) setProfileValues(
        convertBeToFe({ ...form.profile }, PROFILE_MODEL)
      );

      // const hash = window.location.hash;
      // setTimeout(() => jumpToReleventDiv(hash.substring(1)), 1000);
    }
  }, [form]);

  // TH ĐB
  useEffect(() => {
    if (!values) return;
    if (values.foreignLanguage !== 'other' && !!values.otherForeignLanguage) {
      setValues({ ...values, otherForeignLanguage: '' });
    }
  }, [values]);

  const mucProps = { error, values, setValues, dialog, setDialog };

  if (!values) return null;

  return (
    <Fragment>
      <form id='form-dkhs' onSubmit={(e) => {
        const status = e.nativeEvent.submitter.getAttribute('data-status');
        e.preventDefault();
        handleSubmit(status);
      }}>
        <TitleHeader id='muc_a0' marginTop={64} title='A0. Thông tin chung' />
        <MucA0 {...mucProps} />

        <TitleHeader marginTop={64} title='A. Thông tin cá nhân' />
        <MucA
          {...mucProps}
          setPreview={setPreview}
          setDeleteCrud={setDeleteCrud}
          profileValues={profileValues}
          setProfileValues={setProfileValues}
         />

        <TitleHeader marginTop={64} title='B. Tự khai theo tiêu chuẩn chức danh GS/PGS' />
        <MucB {...mucProps} setPreview={setPreview} saveForm={saveForm} />

        <TitleHeader marginTop={64} title='C. Báo cáo khoa học tổng quan' />
        <MucC {...mucProps} />
      
        <TitleHeader marginTop={64} title={'D. ' + CRUD_LABELS.program} />
        <MucD {...mucProps} />

        <TitleHeader marginTop={64} title='E. Minh chứng bản xác nhận thực hiện nhiệm vụ đào tạo và nghiên cứu khoa học của giảng viên' />
        <MucE {...mucProps} />
      </form>

      <PreviewModal open={!!preview} url={preview} handleClose={() => setPreview(null)} />
      <ThanhTichInsert dialog={dialog} values={values} setValues={setValues} setDialog={setDialog} />
      <PopupMenu
        position='center'
        anchorEl={deleteCrud.anchorEl}
        handleClose={closeDeleteMenu}
        render={
          <>
            <Box p={2} width={320} maxWidth={320}>
              <Typography align='center'>
                Xác nhận xoá <b>{CRUD_LABELS?.[deleteCrud.type]}</b>?
              </Typography>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth variant='outlined' onClick={closeDeleteMenu}>
                  Huỷ
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth variant='contained' color='primary' onClick={() => {
                  deleteCrud.handleDelete();
                  closeDeleteMenu();
                }}>
                  Xác nhận
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
    </Fragment>
  );
}

export default DangKyHoSoUpdate;
