import { Badge, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CRUD_LABELS, CRUD_MODELS, HEADERS, SCIENTIFIC_RESEARCH_CONSTANTS } from '../../../constants';
import CrudTable from '../../../components/Crud/CrudTable/CrudTable';
import { journalDomesticActions, userActions } from '../../../actions';
import DuyetThanhTichModal from '../DuyetThanhTichModal';
import ThanhTichInsert from '../../DangKyHoSo/ThanhTichInsert';
import { apiCall, autoScore, downloadFile, fileRender, toPlural } from '../../../utils';
import { Download } from '@mui/icons-material';
import { defaultRows } from '../../../constants/scoringInformation.constants';

const OPTIONS = [
  ...SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE,
  { key: CRUD_MODELS.MONOGRAPH, value: CRUD_MODELS.MONOGRAPH, title: CRUD_LABELS[CRUD_MODELS.MONOGRAPH] }
];

function DanhSachThanhTichTable(props) {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { formReducer } = useSelector((state) => state);

  const journalDomestics = useSelector(state => state.journalDomesticReducer.list);

  const [type, setType] = useState(CRUD_MODELS.SCIENTIFIC_RESEARCH);
  const [dialog, setDialog] = useState(null);
  const [srFilterType, setSrFilterType] = useState(SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE[0].key);
  const [duyetThanhTich, setDuyetThanhTich] = useState(false);

  const handleReload = () => {
    dispatch(journalDomesticActions.filterJournalDomestic({
      page: 1, pageSize: 10000, percent: 0, title: ''
    }));
  }

  const exportFile = async (endPoint) => {
    dispatch(userActions.setLoadingGlobal(true));

    const { response, error } = await apiCall({
      endPoint: '/api/v1/export' + endPoint,
      method: 'GET',
      headers: HEADERS.DEFAULT_HEADER,
    });
    
    if (!error && response.status === 200) {
      downloadFile(fileRender(response.data.data));
    } else {
      dispatch(userActions.notification({ message: 'Xuất dữ liệu thất bại', type: 'error' }));
    }

    dispatch(userActions.setLoadingGlobal(false));
  }

  const scoreInfos = useMemo(() => {
    return [
      ...defaultRows,
      ...journalDomestics.map((journal) => ({
        ...journal,
        journalDomesticId: journal.id,
        type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
        description: `${journal.title} (${journal.publisher})`,
      }))
    ];
  }, [defaultRows, journalDomestics]);

  useEffect(() => {
    if (formReducer.scientificResearchs.length > 0) {
      formReducer.scientificResearchs.forEach((ctkh) => {
        const data = { ...ctkh };
        const found = autoScore('scientificResearch', data, scoreInfos);
        const accur = JSON.parse(formReducer.form.researchSummary)
          .scientificResearchs
          .find((e) => e.id === ctkh.id);
        if ((found.maxPoint || 0) !== (accur?.maxPoint || 0)) {
          console.log(data.orderNumber, ctkh.filterType, data.title, found.maxPoint, accur?.maxPoint);
        }
      });

      formReducer.monographs.forEach((ctkh) => {
        const data = { ...ctkh };
        const found = autoScore('monograph', data, scoreInfos);
        const accur = JSON.parse(formReducer.form.researchSummary)
          .monographs
          .find((e) => e.id === ctkh.id);
        if ((found.maxPoint || 0) !== (accur?.maxPoint || 0)) {
          console.log(data.orderNumber, 'monograph', data.title, found.maxPoint, accur?.maxPoint);
        }
      });
    }
  }, [formReducer]);

  const unAcceptedCounter = useMemo(() => {
    const res = {};
    OPTIONS.forEach((option) => {
      var rows;
      if (formReducer.hasOwnProperty(toPlural(option.key))) rows = formReducer[toPlural(option.key)];
      else rows = formReducer.scientificResearchs.filter((e) => e.filterType === option.key);
      rows = rows || [];
      res[option.key] = rows.filter((e) => !e?.scoringTable?.accepted)?.length;
    }); 
    return res;
  }, [formReducer]);

  useEffect(() => {
    handleReload();
  }, []);

	return (
    <Fragment>
      <Box p={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControl sx={{ m: 1, width: 512 }}>
          <InputLabel id="select-id">Chọn thành tích</InputLabel>
          <Select
            id="select-id"
            size='small'
            label='Chọn thành tích'
            value={type === CRUD_MODELS.SCIENTIFIC_RESEARCH ? srFilterType : type}
            onChange={(e) => {
              const value = e.target.value;
              if (SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE.find((item) => item.key === value)) {
                // Thuộc bài báo
                setType(CRUD_MODELS.SCIENTIFIC_RESEARCH);
                setSrFilterType(value);
              } else {
                setType(value);
              }
            }}
          >
            {OPTIONS.map((type) => (
              <MenuItem key={type.key} value={type.key}>
                <Badge
                  className='srBadge'
                  color="secondary"
                  badgeContent={unAcceptedCounter?.[type.key]}
                >
                  {type.title}
                </Badge>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant='contained'
          size='small'
          color='primary'
          sx={{ my: 1 }}
          onClick={() => exportFile('/6a9/' + id)}
          startIcon={<Download />}
        >
          Xuất file 6A9
        </Button>
      </Box>
      <CrudTable
        type={type}
        setDialog={setDialog}
        rows={formReducer?.[toPlural(type)]}
        filterType={srFilterType}
        setDuyetThanhTich={setDuyetThanhTich}
      />
      <ThanhTichInsert
        dialog={dialog}
        setDialog={setDialog}
      />
      <DuyetThanhTichModal
        type={type}
        data={duyetThanhTich}
        setDuyetThanhTich={setDuyetThanhTich}
      />
    </Fragment>
  );
}

export default DanhSachThanhTichTable;
