export * from './enums';
export * from './router';
export * from './headers';

export * from './user.constants';
export * from './form.constants';
export * from './crud.constants';
export * from './file.constants';
export * from './award.constants';
export * from './reward.constants';
export * from './program.constants';
export * from './council.constants';
export * from './profile.constants';
export * from './solution.constants';
export * from './monograph.constants';
export * from './discipline.constants';
export * from './scoringTable.constants';
export * from './educate_degree.constants';
export * from './scientificTask.constants';
export * from './workingProcess.constants';
export * from './researchProject.constants';
export * from './traineeGuidance.constants';
export * from './journalDomestic.constants';
export * from './educationProcess.constants';
export * from './scienctificResearch.constants';

export const MOBILE_WIDTHS_SIZE = 768;
