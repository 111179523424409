import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";
import { PHASE_OPTIONS } from "./enums";

export const SCIENTIFIC_TASK_CONSTANT = {
  LEVEL_OPTIONS: [
    { value: "nhaNuoc", title: "Nhà nước", key: "nhaNuoc" },
    { value: "bo", title: "Bộ", key: "bo" },
    { value: "coSo", title: "Cơ sở", key: "coSo" },
    { value: "other", title: "Khác", key: "other" }
  ],
  ROLE_OPTIONS: [
    { value: "chuNhiem", title: "Chủ nhiệm", key: "chuNhiem" },
    { value: "phoChuNhiem", title: "Phó chủ nhiệm", key: "phoChuNhiem" },
    { value: "thuKy", title: "Thư ký", key: "thuKy" },
    { value: "thanhVien", title: "Thành viên", key: "thanhVien" },
  ],
  RESULT_OPTIONS: [
    { value: "kha", title: "Khá", key: "kha" },
    { value: "tot", title: "Tốt", key: "tot" },
    { value: "xs", title: "Xuất sắc", key: "xs" },
  ]
};

export const SCIENTIFIC_TASK_MODEL = {
  phase: { key: 'phase', label: 'Giai đoạn nghiệm thu (Trước/Sau TS/PGS)', type: KPTYPE.select, options: PHASE_OPTIONS },
  title: { key: 'title', label: 'Tên', type: KPTYPE.textarea, required: true },
  code: { key: 'code', label: 'Mã số', required: true },
  managementLevel: { key: 'managementLevel', label: 'Cấp quản lý', type: KPTYPE.select, options: SCIENTIFIC_TASK_CONSTANT.LEVEL_OPTIONS, required: true },
  role: { key: 'role', label: 'Vai trò', type: KPTYPE.select, options: SCIENTIFIC_TASK_CONSTANT.ROLE_OPTIONS, required: true },
  startTime: { key: 'startTime', label: 'Thời gian bắt đầu', type: KPTYPE.date },
  endTime: { key: 'endTime', label: 'Thời gian kết thúc', type: KPTYPE.date },
  inspectTime: { key: 'inspectTime', label: 'Thời gian nghiệm thu', type: KPTYPE.date, required: true },
  result: { key: 'result', label: 'Kết quả', type: KPTYPE.select, options: SCIENTIFIC_TASK_CONSTANT.RESULT_OPTIONS, required: true },
//   result: { key: 'result', label: 'Kết quả', required: true },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone, required: true },
  researchDirectionId: { key: 'researchDirectionId', label: 'Hướng nghiên cứu' },
};
