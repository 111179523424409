import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from "lodash";
import {
  Box,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';

import {
  HEADERS,
  PHASE_OPTIONS,
  SCIENTIFIC_RESEARCH_MODEL,
  SCIENTIFIC_RESEARCH_CONSTANTS,
} from '../../../../constants';
import { getDoi } from '../../../../utils';
import { userActions } from '../../../../actions';
import KPTextform, { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import DefaultLabel from '../../../../components/CommonComponents/DefaultLabel';
import theme from '../../../../theme';

function BaiBaoForm({ filterType, ...props }) {
  const dispatch = useDispatch();
  const { values, setValues, disabled } = props;
  const { ROLE_OPTIONS, TYPE_OPTIONS, FILTER_TYPE } = SCIENTIFIC_RESEARCH_CONSTANTS;

  const [options, setOptions] = useState([]);
  const [loadingDoi, setLoadingDoi] = useState(false);
  const [disabledDoi, setDisabledDoi] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const dOptions = useSelector((state) => state.formReducer.researchDirections?.map(
    (e, id) => ({ key: e.id, value: e.id, title: `[${id + 1}] ${e.title}` })
  )) || [];

  const doiButton =  {
    key: 'doi-btn',
    xs: 3,
    disabled,
    type: KPTYPE.button,
    color: 'primary',
    variant: 'contained',
    labelRender: <></>,
    label: loadingDoi ? <CircularProgress size={24} style={{ color: '#fff' }} /> : 'Nhập từ DOI',
    onClick: async () => {
      dispatch(userActions.setLoadingGlobal(true));
      setLoadingDoi(true);
      const doi = await getDoi(values[SCIENTIFIC_RESEARCH_MODEL.doi.key]);
      if (doi.success) {
        const { data: { info, journal } } = doi;

        if (!journal) {
          setLoadingDoi(false);
          dispatch(userActions.notification({ message: 'Lấy dữ liệu DOI thất bại', type: 'error' }));
          dispatch(userActions.setLoadingGlobal(false));
          return;
        }

        var isScopus = false;
        isScopus = SCIENTIFIC_RESEARCH_CONSTANTS.TYPE_OPTIONS.find(
          (t) => t.value === journal?.sjrBestQuartile?.toLowerCase()
        );
        // const coverageStr = journal.coverage;
        const publishedYear = parseInt(info?.issued?.['date-parts']?.[0]?.[0] || info?.publishedYear);
        const publishedMonth = parseInt(info?.issued?.['date-parts']?.[0]?.[1]);
        // if (coverageStr) {
        //   const coverages = coverageStr.replaceAll(' ', '').split(';');
        //   coverages.forEach((cov) => {
        //     if (cov.includes('-')) {
        //       // Is a interval
        //       const start = parseInt(cov.split('-')[0]);
        //       const end = parseInt(cov.split('-')[1]);

        //       if (start <= publishedYear && publishedYear <= end) {
        //         isScopus = true;
        //       }
        //     } else {
        //       // Is a single integer
        //       const year = parseInt(cov);
        //       if (publishedYear == year) {
        //         isScopus = true;
        //       }
        //     }
        //   });
        // }

        setValues({
          ...values,
          journalTitle: journal.type?.toLowerCase() === 'journal' ? journal.title : null,
          type: journal.sjrBestQuartile?.toLowerCase(),
          link: info?.URL || info?.doi,
          title: info?.title || info?.titleEn,
          publisher: info?.publisher || info?.publisherId,
          publishedYear,
          publishedMonth,
          page: info?.page,
          volume: info?.volume,
          issn: info?.ISSN?.[0] || info?.journal?.issn,
          numberAuthor: info?.author?.length || info?.contributorList?.length,
          // numberMain: info?.author?.filter((e) => e.sequence === 'first')?.length || info?.contributorList?.length,
          author: info?.author
            ? info?.author?.map((e) => (`${e.given} ${e.family}`)).join(', ')
            : info?.contributorList?.map((e) => (e.surnameEn || `${e.surname}${e.givenName ? ` ${e.givenName}` : ''}`)).join('; '),
          jif: parseFloat(journal.jif),
          jifYear: journal.jifYear,
          issue: info?.issue,

          journalId: journal.id,
          journalHIndex: journal.hIndex,
          journalCountry: journal.country,
          journalCoreCollection: isScopus ? journal.coreCollection : journal.coreCollection.replace('scopus', ''),
        });
        setLoadingDoi(false);
        dispatch(userActions.setLoadingGlobal(false));
        // setDisabledDoi(true);
        dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
      } else {
        setLoadingDoi(false);
        dispatch(userActions.notification({ message: doi.message, type: 'error' }));
      }
      dispatch(userActions.setLoadingGlobal(false));
    },
  }

  const fetchHandler = async (inputValue) => {
    setLoadingFilter(true);
    let responseJournal = null, errorJournal = null;

    try {
      responseJournal = await axios({
        url: process.env.REACT_APP_DOMAIN + '/api/v1/journalDomestic/filter',
        method: 'POST',
        data: { title: inputValue, percent: 0.5, page: 1, pageSize: 10 },
        headers: HEADERS.DEFAULT_HEADER,
      });
      
      if (!errorJournal && responseJournal?.status === 200) {
        setOptions(responseJournal.data.data.list.map((opt) => ({
          ...opt,
          value: opt.id,
          // label: opt.title,
          label: `${opt.title} - ${opt.issn}`
        })));
      }
    } catch (error) {
      console.error("Error fetching options", error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const debounceFetchOptions = useCallback(debounce(fetchHandler, 300), []);

  useEffect(() => {
    return () => debounceFetchOptions.cancel();
  }, [debounceFetchOptions]);

  const textForm = useMemo(() => {
    switch (values.filterType || filterType) {
      case FILTER_TYPE[0].key:
        // Tạp chí uy tín
        return [
          { ...SCIENTIFIC_RESEARCH_MODEL.doi, xs: 9, disabled },
          doiButton,
          { key: 'doi-noti', type: KPTYPE.render, render: () => (

            <Typography color={theme.palette.info.dark}>
              <b>Lưu ý: </b>Trong trường hợp bài báo không có link DOI, 
              vui lòng để trống và điển tay những thông tin phía dưới!
            </Typography>
          ) },
          { ...SCIENTIFIC_RESEARCH_MODEL.title, xs: 12, height: 1, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalTitle, xs: 12, disabled },
          {
            ...SCIENTIFIC_RESEARCH_MODEL.journalCoreCollection,
            xs: 12,
            disabled,
            type: KPTYPE.render,
            render: ({ values, handleChangeForm, id }) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {SCIENTIFIC_RESEARCH_CONSTANTS.CORE_COLECTION_OPTIONS.map((opt) => (
                  <FormControlLabel
                    key={opt.key}
                    disabled={disabled}
                    control={
                      <Checkbox
                        checked={!!values.journalCoreCollection?.includes(opt.key)}
                        onChange={() => {
                          if (opt.key) {
                            var newVal = values.journalCoreCollection || '';
                            if (newVal.includes(opt.key)) {
                              newVal = newVal.replaceAll(opt.key, '')
                            } else {
                              if (newVal !== '') newVal += '|';
                              newVal += opt.key;
                            }
                            setValues({ ...values, journalCoreCollection: newVal });
                          }
                        }}
                      />
                    }
                    label={<Typography className='kpt-textfield-label' sx={{ pb: 0 }}
                  >
                    <b>{opt.title}</b>
                  </Typography>} />
                ))}
              </Box>
            )
          },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalHIndex, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.type, xs: 6, type: KPTYPE.select, options: TYPE_OPTIONS, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalCountry, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedMonth, type: KPTYPE.int, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedYear, type: KPTYPE.int, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publisher, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.jif, xs: 4, disabled, label: `Impact factor ${values.jifYear ? `(${values.jifYear})` : ''}` },
          { ...SCIENTIFIC_RESEARCH_MODEL.issn, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberAuthor, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.author, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issue, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.volume, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.page, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.phase, type: KPTYPE.select, options: PHASE_OPTIONS, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.totalRefs, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberMain, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.role, xs: 6, type: KPTYPE.select, options: ROLE_OPTIONS, disabled },
          // { ...SCIENTIFIC_RESEARCH_MODEL.isRepresentative, type: KPTYPE.checkbox, labelRender: <></>, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.note, xs: 12, labelRender: (
            <DefaultLabel
              label={SCIENTIFIC_RESEARCH_MODEL.note.label + ' của ứng viên gửi tới Hội đồng'}
            />
          ), disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.filterType, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.proof, xs: 12, disabled },
        ];
      case FILTER_TYPE[1].key:
        // Tạp chí quốc tế khác
        return [
          { ...SCIENTIFIC_RESEARCH_MODEL.doi, required: false, xs: 9, disabled },
          doiButton,
          { ...SCIENTIFIC_RESEARCH_MODEL.title, xs: 12, disabled, height: 1  },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalTitle, xs: 12, disabled},
          { ...SCIENTIFIC_RESEARCH_MODEL.publisher, required: false, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalCountry, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issn, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedMonth, type: KPTYPE.int, required: false, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedYear, type: KPTYPE.int, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.author, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberAuthor, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issue, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.volume, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.page, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.phase, type: KPTYPE.select, options: PHASE_OPTIONS, xs: 6 },
          { ...SCIENTIFIC_RESEARCH_MODEL.totalRefs, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberMain, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.role, xs: 6, type: KPTYPE.select, options: ROLE_OPTIONS, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.belongTo, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.note, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.filterType, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.proof, xs: 12, disabled },
        ];
      case FILTER_TYPE[2].key:
        // Hội thảo
        return [
          { ...SCIENTIFIC_RESEARCH_MODEL.doi, required: false, xs: 9, disabled },
          doiButton,
          { ...SCIENTIFIC_RESEARCH_MODEL.title, xs: 12, disabled, height: 1  },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalTitle, label: 'Tên kỷ yếu hội thảo', xs: 12, disabled},
          { ...SCIENTIFIC_RESEARCH_MODEL.publisher, required: false, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issn, xs: 4, required: false, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedMonth, type: KPTYPE.int, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedYear, type: KPTYPE.int, xs: 4, disabled },
          {
            ...SCIENTIFIC_RESEARCH_MODEL.belongTo,
            xs: 6,
            type: KPTYPE.select,
            required: true,
            label: 'Là hội nghị trong nước/quốc tế?',
            options: [
              { key: 'international', value: 'international', title: 'Quốc tế' },
              { key: 'domestic', value: 'domestic', title: 'Trong nước' },
            ],
            disabled
          },
          { ...SCIENTIFIC_RESEARCH_MODEL.journalCountry, xs: 6, disabled },
          // {
          //   ...SCIENTIFIC_RESEARCH_MODEL.journalCountry,
          //   xs: 6,
          //   type: KPTYPE.select,
          //   required: true,
          //   label: 'Là hội nghị trong nước/quốc tế?',
          //   options: [
          //     { key: 'international', value: 'international', title: 'Quốc tế' },
          //     { key: 'domestic', value: 'domestic', title: 'Trong nước' },
          //   ],
          //   disabled
          // },
          { ...SCIENTIFIC_RESEARCH_MODEL.author, required: false, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issue, required: false, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.volume, required: false, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.page, required: false, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.phase, type: KPTYPE.select, options: PHASE_OPTIONS, xs: 6 },
          { ...SCIENTIFIC_RESEARCH_MODEL.totalRefs, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberMain, required: false, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberAuthor, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.role, xs: 6, type: KPTYPE.select, options: ROLE_OPTIONS, disabled },
          // { ...SCIENTIFIC_RESEARCH_MODEL.type, xs: 5, type: KPTYPE.select, options: TYPE_OPTIONS, disabled },
          // { ...SCIENTIFIC_RESEARCH_MODEL.isRepresentative, type: KPTYPE.checkbox, labelRender: <></>, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.note, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.filterType, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.proof, xs: 12, disabled },
        ];
      case FILTER_TYPE[3].key:
        // Tạp chí trong nước
        return [
          { ...SCIENTIFIC_RESEARCH_MODEL.doi, xs: 9, required: false, disabled },
          doiButton,
          { ...SCIENTIFIC_RESEARCH_MODEL.title, xs: 12, disabled, height: 1  },
          {
            ...SCIENTIFIC_RESEARCH_MODEL.journalTitle,
            label: 'Tên tạp chí khoa học trong nước',
            xs: 12,
            type: KPTYPE.render,
            render: ({ value }) => {
              console.log(value);
              return (
                <Autocomplete
                  value={value}
                  options={options}
                  loading={loadingFilter}
                  // getOptionLabel={(option) => option ? `${option.title} - ${option.issn}` : ""}
                  // getOptionLabel={(option) => option.title}
                  onInputChange={(_, newInputValue) => {
                    // console.log('newInputValue', newInputValue);
                    // if (newInputValue != undefined && !newInputValue.includes('undefined')) 
                    debounceFetchOptions(newInputValue);
                  }}
                  renderInput={(params) => <TextField {...params} size='small' variant='outlined' />}
                  isOptionEqualToValue={(option, value) => {
                    console.log('option - value', option, value);
                    return value.includes(option.title) || value.includes(option.issn);
                    return option.title === value || option.issn === value;
                  }}
                  onChange={(_, value, __) => {
                    // console.log(value);
                    setValues({
                      ...values,
                      issn: value?.issn,
                      publisher: value?.publisher,
                      journalTitle: value?.label,
                      journalDomesticId: value?.value,
                    });
                  }}
                />
              )
            }
          },
          { ...SCIENTIFIC_RESEARCH_MODEL.publisher, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issn, xs: 4, required: false, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedMonth, type: KPTYPE.int, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.publishedYear, type: KPTYPE.int, xs: 4, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.author, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberAuthor, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.issue, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.volume, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.page, xs: 3, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.phase, type: KPTYPE.select, options: PHASE_OPTIONS, xs: 6 },
          { ...SCIENTIFIC_RESEARCH_MODEL.totalRefs, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.numberMain, xs: 6, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.role, xs: 6, type: KPTYPE.select, options: ROLE_OPTIONS, disabled },
          // { ...SCIENTIFIC_RESEARCH_MODEL.type, xs: 5, type: KPTYPE.select, options: TYPE_OPTIONS, disabled },
          // { ...SCIENTIFIC_RESEARCH_MODEL.isRepresentative, type: KPTYPE.checkbox, labelRender: <></>, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.note, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.filterType, xs: 12, disabled },
          { ...SCIENTIFIC_RESEARCH_MODEL.proof, xs: 12, disabled },
        ];
      default:
        return [];
    }
  }, [props, values.filterType, values, filterType, loadingDoi, loadingFilter, options]);

  console.log(values);
  
  return <KPTextform {...props} textForm={textForm} />
}

export default BaiBaoForm;
