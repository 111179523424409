import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";
import { SR_BASE_MODEL } from "./scienctificResearch.constants";

export const TRAINEE_GUIDANCE_CONSTANTS = {
  TARGET_OPTIONS: [
    { key: 'ncs', title: 'NCS', value: 'ncs' },
    { key: 'hv', title: 'HVCH/CK2/BSNT', value: 'hv' },
  ],
  ROLE_OPTIONS: [
    { key: 'main', title: 'Chính', value: 'main' },
    { key: 'member', title: 'Phụ', value: 'member' },
  ],
};

export const TRAINEE_GUIDANCE_MODEL = {
  ...SR_BASE_MODEL,
  role: { key: 'role', label: 'Trách nhiệm HD', type: KPTYPE.select, options: TRAINEE_GUIDANCE_CONSTANTS.ROLE_OPTIONS, required: true },
  title: { key: 'title', label: 'Họ và tên', required: true },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone, required: true },
  target: { key: 'target', label: 'Đối tượng', type: KPTYPE.select, options: TRAINEE_GUIDANCE_CONSTANTS.TARGET_OPTIONS, required: true },
  endTime: { key: 'endTime', label: 'Đến', type: KPTYPE.date, required: true },
  startTime: { key: 'startTime', label: 'Từ', type: KPTYPE.date, required: true },
  issueDate: { key: 'issueDate', label: 'Năm được cấp bằng', type: KPTYPE.date, required: true },
  trainingBase: { key: 'trainingBase', label: 'Cơ sở đào tạo', required: true },
  researchDirectionId: { key: 'researchDirectionId', label: 'Hướng nghiên cứu' },
};

export const TRAINEE_GUIDANCE_TYPE = {
  INSERT_TRAINEE_GUIDANCE_LOADING: 'INSERT_TRAINEE_GUIDANCE_LOADING',
  INSERT_TRAINEE_GUIDANCE_SUCCESS: 'INSERT_TRAINEE_GUIDANCE_SUCCESS',
  INSERT_TRAINEE_GUIDANCE_FAILURE: 'INSERT_TRAINEE_GUIDANCE_FAILURE',
  
  UPDATE_TRAINEE_GUIDANCE_LOADING: 'UPDATE_TRAINEE_GUIDANCE_LOADING',
  UPDATE_TRAINEE_GUIDANCE_SUCCESS: 'UPDATE_TRAINEE_GUIDANCE_SUCCESS',
  UPDATE_TRAINEE_GUIDANCE_FAILURE: 'UPDATE_TRAINEE_GUIDANCE_FAILURE',

  DELETE_TRAINEE_GUIDANCE_LOADING: 'DELETE_TRAINEE_GUIDANCE_LOADING',
  DELETE_TRAINEE_GUIDANCE_SUCCESS: 'DELETE_TRAINEE_GUIDANCE_SUCCESS',
  DELETE_TRAINEE_GUIDANCE_FAILURE: 'DELETE_TRAINEE_GUIDANCE_FAILURE',
}
