import {
  CRUD_MODELS,
  CRUD_TYPE,
  FORM_TYPE,
  FORM_MODEL,
  AWARD_MODEL,
  REWARD_MODEL,
  PROGRAM_MODEL,
  SOLUTION_MODEL,
  MONOGRAPH_MODEL,
  DISCIPLINE_MODEL,
  EDUCATE_DEGREE_MODEL,
  WORKING_PROCESS_MODEL,
  SCIENTIFIC_TASK_MODEL,
  RESEARCH_PROJECT_MODEL,
  TRAINEE_GUIDANCE_MODEL,
  RESEARCH_DIRECTION_MODEL,
  SCIENTIFIC_RESEARCH_MODEL,
  SCORING_TABLE_TYPE,
} from '../constants';
import { convertBeToFe, toPlural } from "../utils";

const formatList = (list, model) => (list || [])
  .map((e) => convertBeToFe(e, model))
  .sort((a, b) => {
    if (a.hasOwnProperty('orderNumber')) {
      return a.orderNumber - b.orderNumber;
    } else {
      return a.id - b.id;
    }
  });


export const FORM_MODELS = {
  [CRUD_MODELS.AWARD]: AWARD_MODEL,
  [CRUD_MODELS.REWARD]: REWARD_MODEL,
  [CRUD_MODELS.PROGRAM]: PROGRAM_MODEL,
  [CRUD_MODELS.SOLUTION]: SOLUTION_MODEL,
  [CRUD_MODELS.MONOGRAPH]: MONOGRAPH_MODEL,
  [CRUD_MODELS.DISCIPLINE]: DISCIPLINE_MODEL,
  [CRUD_MODELS.EDUCATE_DEGREE]: EDUCATE_DEGREE_MODEL,
  [CRUD_MODELS.SCIENTIFIC_TASK]: SCIENTIFIC_TASK_MODEL,
  [CRUD_MODELS.WORKING_PROCESS]: WORKING_PROCESS_MODEL,
  [CRUD_MODELS.RESEARCH_PROJECT]: RESEARCH_PROJECT_MODEL,
  [CRUD_MODELS.TRAINEE_GUIDANCE]: TRAINEE_GUIDANCE_MODEL,
  [CRUD_MODELS.RESEARCH_DIRECTION]: RESEARCH_DIRECTION_MODEL,
  [CRUD_MODELS.SCIENTIFIC_RESEARCH]: SCIENTIFIC_RESEARCH_MODEL,
}

const initialState = {
  loading: false,
  form: null,
  list: [],
  awards: [],
  rewards: [],
  programs: [],
  solutions: [],
  monographs: [],
  disciplines: [],
  educateDegrees: [],
  scientificTask: [],
  traineeGuidances: [],
  researchProjects: [],
  researchDirections: [],
  scientificResearchs: [],
  pastWorkingProcesses: [],
}

export function formReducer(state = initialState, action) {
  switch (action.type) {
    case FORM_TYPE.FILTER_APPLICATION_FORM_LOADING:
    case FORM_TYPE.INSERT_APPLICATION_FORM_LOADING:
    case FORM_TYPE.UPDATE_APPLICATION_FORM_LOADING:
    case FORM_TYPE.GET_ALL_APPLICATION_FORM_LOADING:
    case FORM_TYPE.GET_APPLICATION_FORM_BY_ID_LOADING:
    case CRUD_TYPE.INSERT_CRUD_LOADING:
    case CRUD_TYPE.UPDATE_CRUD_LOADING:
    case CRUD_TYPE.DELETE_CRUD_LOADING:
    case SCORING_TABLE_TYPE.INSERT_SCORING_TABLE_LOADING:
    case SCORING_TABLE_TYPE.UPDATE_SCORING_TABLE_LOADING:
      return { ...state, loading: true };

    case FORM_TYPE.FILTER_APPLICATION_FORM_FAILURE:
    case FORM_TYPE.FILTER_APPLICATION_FORM_SUCCESS:
    case FORM_TYPE.INSERT_APPLICATION_FORM_FAILURE:
    case FORM_TYPE.UPDATE_APPLICATION_FORM_FAILURE:
    case FORM_TYPE.GET_ALL_APPLICATION_FORM_FAILURE:
    case FORM_TYPE.GET_APPLICATION_FORM_BY_ID_FAILURE:
    case CRUD_TYPE.INSERT_CRUD_FAILURE:
    case CRUD_TYPE.UPDATE_CRUD_FAILURE:
    case CRUD_TYPE.DELETE_CRUD_FAILURE:
    case SCORING_TABLE_TYPE.INSERT_SCORING_TABLE_FAILURE:
    case SCORING_TABLE_TYPE.UPDATE_SCORING_TABLE_FAILURE:
      return { ...state, loading: false };
      
    case FORM_TYPE.INSERT_APPLICATION_FORM_SUCCESS:
    case FORM_TYPE.UPDATE_APPLICATION_FORM_SUCCESS:
      return { ...state, form: convertBeToFe(action.payload, FORM_MODEL), loading: false };

    case CRUD_TYPE.INSERT_CRUD_SUCCESS: {
      const key = toPlural(action.model);
      const model = FORM_MODELS[action.model];
      if (model) {
        const newList = [...state[key], action.payload];
        return { ...state, [key]: formatList(newList, model), loading: false };
      }
    }

    case CRUD_TYPE.UPDATE_CRUD_SUCCESS: {
      const key = toPlural(action.model);
      const model = FORM_MODELS[action.model];
      if (model) {
        const newList = [...state[key].filter((e) => e.id !== action.payload.id), action.payload];
        return { ...state, [key]: formatList(newList, model), loading: false };
      }
    }

    case CRUD_TYPE.DELETE_CRUD_SUCCESS:
      const key = toPlural(action.model);
      const model = FORM_MODELS[action.model];
      if (model) {
        const newList = state[key].filter((e) => e.id !== action.id);
        return { ...state, [key]: formatList(newList, model), loading: false };
      }

    case FORM_TYPE.GET_ALL_APPLICATION_FORM_SUCCESS:
      return { ...state, list: action.payload, loading: false };

    case FORM_TYPE.GET_APPLICATION_FORM_BY_ID_SUCCESS: {
      const newState = { form: convertBeToFe(action.payload, FORM_MODEL), loading: false };
      Object.keys(CRUD_MODELS).forEach((_key) => {
        const key = toPlural(CRUD_MODELS[_key]);
        newState[key] = formatList(action.payload[key], FORM_MODELS[CRUD_MODELS[_key]]);
      });
      return { ...state, ...newState };
    }

    case SCORING_TABLE_TYPE.INSERT_SCORING_TABLE_SUCCESS:
    case SCORING_TABLE_TYPE.UPDATE_SCORING_TABLE_SUCCESS: {
      const key = toPlural(action.payload.modelType);
      const list = [...state[key]];
      const index = list.findIndex((item) => item.id === action.payload.modelId);
      list[index].scoringTable = action.payload;
      return { ...state, loading: false, [key]: list };
    }

    default:
      return state;
  }
}
