import React from 'react';
import { Box, Typography } from '@mui/material';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import DefaultLabel from '../../../../components/CommonComponents/DefaultLabel';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { WORKING_PROCESS_MODEL } from '../../../../constants';

function CongTacForm(props) {
  const { disabled } = props;

  const textForm = [
    {
      key: 'labelx',
      xs: 12,
      labelRender: <DefaultLabel label='Thời gian công tác' required={true} />,
      type: KPTYPE.render,
      render: () => null,
    },
    { ...WORKING_PROCESS_MODEL.startTime, xs: 5, labelRender: <></>, required: true },
    { key: 'to_0', xs: 2, labelRender: <Box />, type: KPTYPE.render, render: () => (
      <Box sx={{ height: 39, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography className='kpt-textfield-label'>đến</Typography>
      </Box>
    ) },
    { ...WORKING_PROCESS_MODEL.endTime, xs: 5, labelRender: <></>, required: true },

    { ...WORKING_PROCESS_MODEL.unit, xs: 12, disabled },
    { ...WORKING_PROCESS_MODEL.position, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default CongTacForm;
