import { HEADERS } from '.';

export const ACTION_TYPE = {
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

export const CRUD_MODELS = {
  AWARD: 'award',
  REWARD: 'reward',
  PROGRAM: 'program',
  SOLUTION: 'solution',
  MONOGRAPH: 'monograph',
  DISCIPLINE: 'discipline',
  EDUCATE_DEGREE: 'educateDegree',
  EDUCATE_PROCESS: 'educateProcess',
  WORKING_PROCESS: 'pastWorkingProcess',
  SCIENTIFIC_TASK: 'scientificTask',
  RESEARCH_PROJECT: 'researchProject',
  TRAINEE_GUIDANCE: 'traineeGuidance',
  RESEARCH_DIRECTION: 'researchDirection',
  SCIENTIFIC_RESEARCH: 'scientificResearch',
}

export const CRUD_LABELS = {
  [CRUD_MODELS.AWARD]: 'Tác phẩm nghệ thuật, thành tích huấn luyện, thi đấu thể dục thể thao đạt giải thưởng quốc gia, quốc tế (đối với ngành Văn hóa, nghệ thuật, thể dục thể thao)',
  [CRUD_MODELS.REWARD]: 'Khen thưởng (các huân chương, huy chương, danh hiệu)',
  [CRUD_MODELS.PROGRAM]: 'Phát triển chương trình đào tạo, khoa học và công nghệ (đối với ứng viên GS)',
  [CRUD_MODELS.SOLUTION]: 'Bằng độc quyền sáng chế, giải pháp hữu ích',
  [CRUD_MODELS.MONOGRAPH]: 'Biên soạn sách phục vụ đào tạo từ trình độ đại học trở lên',
  [CRUD_MODELS.DISCIPLINE]: 'Kỷ luật',
  [CRUD_MODELS.EDUCATE_DEGREE]: 'Trình độ đào tạo',
  [CRUD_MODELS.WORKING_PROCESS]: 'Quá trình công tác trước đây',
  [CRUD_MODELS.SCIENTIFIC_TASK]: 'Thực hiện nhiệm vụ khoa học và công nghệ đã nghiệm thu',
  [CRUD_MODELS.TRAINEE_GUIDANCE]: 'Hướng dẫn NCS, HVCH/CK2/BSNT đã được cấp bằng/có quyết định cấp bằng',
  [CRUD_MODELS.RESEARCH_PROJECT]: 'Chủ trì hoặc tham gia xây dựng, phát triển chương trình đào tạo hoặc chương trình/dự án/đề tài nghiên cứu, ứng dụng khoa học công nghệ của cơ sở giáo dục đại học đã được đưa vào áp dụng thực tế',
  [CRUD_MODELS.RESEARCH_DIRECTION]: 'Các hướng nghiên cứu chủ yếu',
  [CRUD_MODELS.SCIENTIFIC_RESEARCH]: 'Bài báo khoa học, báo cáo khoa học đã công bố',
}

export const CRUD_TYPE = {
  INSERT_CRUD_LOADING: 'INSERT_CRUD_LOADING',
  INSERT_CRUD_SUCCESS: 'INSERT_CRUD_SUCCESS',
  INSERT_CRUD_FAILURE: 'INSERT_CRUD_FAILURE',

  UPDATE_CRUD_LOADING: 'UPDATE_CRUD_LOADING',
  UPDATE_CRUD_SUCCESS: 'UPDATE_CRUD_SUCCESS',
  UPDATE_CRUD_FAILURE: 'UPDATE_CRUD_FAILURE',

  DELETE_CRUD_LOADING: 'DELETE_CRUD_LOADING',
  DELETE_CRUD_SUCCESS: 'DELETE_CRUD_SUCCESS',
  DELETE_CRUD_FAILURE: 'DELETE_CRUD_FAILURE',
}

export const CRUD_API = {
  insert: (model) => ({
    endPoint: `/api/v1/${model}/create`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  update: (model) => ({
    endPoint: `/api/v1/${model}/update`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  delete: (model, id) => ({
    endPoint: `/api/v1/${model}/${id}`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'DELETE',
  }),
}
