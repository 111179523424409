import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';
import { PHASE_OPTIONS } from './enums';

export const AWARD_CONSTANTS = {
  LEVEL_OPTIONS: [
    { key: 'domestic', value: 'domestic', title: 'Quốc Gia' },
    { key: 'international', value: 'international', title: 'Quốc Tế' }
  ],
  ROLE_OPTIONS: [
    { key: 'main', title: 'Chính', value: 'main' },
    { key: 'member', title: 'Phụ', value: 'member' },
  ],
};

export const AWARD_MODEL = {
  phase: { key: 'phase', label: 'Giai đoạn', type: KPTYPE.select, options: PHASE_OPTIONS, required: true },
  title: { key: 'title', label: 'Tên', required: true },
  issueDate: { key: 'issueDate', label: 'Ngày cấp', type: KPTYPE.date, required: true },
  organizationName: { key: 'organizationName', label: 'Tên cơ quan công nhận', required: true },
  recognitionDocument: { key: 'recognitionDocument', label: 'Văn bản công nhận', required: true },
  level: { key: 'level', label: 'Cấp giải thưởng', type: KPTYPE.select, options: AWARD_CONSTANTS.LEVEL_OPTIONS, required: true },
  authorNumber: { key: 'authorNumber', label: 'Số tác giả', type: KPTYPE.int, required: true },
  role: { key: 'role', label: 'Là tác giả chính', type: KPTYPE.select, options: AWARD_CONSTANTS.ROLE_OPTIONS, required: true },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone, required: true }
};
