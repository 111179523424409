import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";

export const REWARD_CONSTANTS = {
  LEVEL_OPTIONS: [
    { key: 'ministry', title: 'Cấp Bộ và tương đương', value: 'ministry' },
    { key: 'domestic', value: 'domestic', title: 'Quốc Gia' },
    { key: 'international', value: 'international', title: 'Quốc Tế' }
  ]
};

export const REWARD_MODEL = {
  title: { key: 'title', label: 'Tên khen thưởng', type: KPTYPE.textarea, height: 1, required: true },
  level: { key: 'level', label: 'Cấp khen thưởng' },
  year: { key: 'year', label: 'Năm khen thưởng', type: KPTYPE.int },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone, required: true }
};
