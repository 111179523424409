import React from "react";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Card, CardHeader, IconButton, Typography } from "@mui/material";

import CrudTable from "../../../components/Crud/CrudTable/CrudTable";

import { CRUD_LABELS, CRUD_MODELS } from "../../../constants/crud.constants";
import { USER_CONSTANTS } from "../../../constants";
import { toPlural } from "../../../utils";

const KEY = CRUD_MODELS.PROGRAM;

function MucD({ setDialog, setDeleteCrud }) {
  const { id } = useParams();
  const { formReducer } = useSelector((state) => state);

  const profile = useSelector(state => state.profileReducer.profile);

  const disabled = !!id && profile?.userRole !== USER_CONSTANTS.ROLE.super_admin;

  return (
    <Card id='muc_d' style={{ width: '100%', marginTop: 16 }}>
      <CardHeader sx={{ py: 1, pr: 1 }} title={
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography><b>{CRUD_LABELS.program}</b></Typography>
          <IconButton disabled={disabled} color='primary' onClick={() => setDialog({ defaultValues: {}, type: KEY })}><Add /></IconButton>
        </Box>
      } />
      <CrudTable
        type={KEY}
        rows={formReducer[toPlural(KEY)]}
        setDialog={setDialog}
        setDeleteCrud={setDeleteCrud}
      />
    </Card>
  )
}

export default MucD;
